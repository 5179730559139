.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-cuenta {
  background-image: linear-gradient(
    210deg,
    #549bd4 0,
    #1a3989 30%,
    #1a3989 50%,
    #1a3989 75%
  );
  border-radius: 15px;
  text-align: center;
  justify-content: center;
  height: 80px;
}
.App-functions {
  padding-bottom: 25pt;
}
.cuenta-header {
  text-align: center;
  padding-bottom: 25px;
  color: #0d204c;
  font-family: "Roboto";
  font-size: 170%;
}
.cuenta-footer {
  text-align: center;
  color: #0d204c;
  font-family: "Roboto";
  font-size: 150%;
}
.App-buttonR {
  margin: "1rem";
  background-color: #e66031;
  background-image: linear-gradient(
    to bottom,
    #e9802f,
    #e8762f,
    #e76b30,
    #e66031,
    #e45533
  );
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-buttonR {
  margin: "1rem";
  color: "#FFF";
  background-color: #e66031;
}
.App-more {
  border-radius: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
